import { OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import * as H from 'crypto-js/enc-hex';
import * as SH from 'crypto-js/sha1';
import { isString, get, isArray } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { IRect } from 'src/app/providers/_interfaces/common';
import { IDictionaryItem } from 'src/app/providers/_interfaces/dictionary';
import { INavTabFromBackend, INavTab } from 'src/app/providers/_interfaces/nav-tabs.interface';
import { IPagination, IPaginationChangeEvent } from 'src/app/providers/_interfaces/pagination.interface';
import { UITextCounted } from 'src/app/providers/_text/counted';
import { environment } from 'src/environments/environment';

export function getUrlSearchParams() {
  return new URLSearchParams(window.location.search);
}

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

export function transformBooleanToString(data: any): string {
  switch ('' + data) {
    case 'true': {
      return 'true';
    }
    case 'false': {
      return 'false';
    }
  }
  return data;
}

export function transformStringToBoolean(data: string | number): string | number | boolean {
  switch ('' + data) {
    case 'true': {
      return true;
    }
    case 'false': {
      return false;
    }
  }
  return data;
}

export function isHTMLElement(o: any): boolean {
  return typeof HTMLElement === 'object'
    ? o instanceof HTMLElement //DOM2
    : o && typeof o === 'object' && o !== null && o.nodeType === 1 && typeof o.nodeName === 'string';
}

export function getOldDataWithoutPrefix<T>(data: any) {
  const acc: { [key: string]: any } = {};

  for (const key of Object.keys(data)) {
    if (key.indexOf('old_') >= 0) {
      const nKey = key.substring(4);

      acc[nKey] = data[key];
    }
  }

  return acc as T;
}

export function getSH(b, t) {
  return SH(b + t.slice(0, -1));
}

export function getPointGrid(fill: string) {
  return `<?xml version="1.0" standalone="no"?>
  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
   "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280.000000 1280.000000">
  <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" stroke="none" fill=${fill}>
  <path d="M550 12465 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M1830 12465 c-151 -50 -253 -216 -222 -362 25 -119 136 -230 254
  -255 194 -41 395 142 375 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217
  16z"/>
  <path d="M3110 12465 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M4390 12465 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M5670 12465 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M6950 12465 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M8230 12465 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M9510 12465 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M10790 12465 c-105 -35 -200 -141 -222 -248 -25 -117 32 -244 142
  -319 107 -74 229 -75 337 -3 177 118 201 338 53 485 -85 86 -207 119 -310 85z"/>
  <path d="M12070 12465 c-105 -35 -200 -141 -222 -248 -25 -117 32 -244 142
  -319 107 -74 229 -75 337 -3 177 118 201 338 53 485 -85 86 -207 119 -310 85z"/>
  <path d="M550 11185 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M1830 11185 c-151 -50 -253 -216 -222 -362 25 -119 136 -230 254
  -255 194 -41 395 142 375 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217
  16z"/>
  <path d="M3110 11185 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M4390 11185 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M5670 11185 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M6950 11185 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M8230 11185 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M9510 11185 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M10790 11185 c-105 -35 -200 -141 -222 -248 -25 -117 32 -244 142
  -319 107 -74 229 -75 337 -3 177 118 201 338 53 485 -85 86 -207 119 -310 85z"/>
  <path d="M12070 11185 c-105 -35 -200 -141 -222 -248 -25 -117 32 -244 142
  -319 107 -74 229 -75 337 -3 177 118 201 338 53 485 -85 86 -207 119 -310 85z"/>
  <path d="M550 9905 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369 -369
  155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M1830 9905 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M3110 9905 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M4390 9905 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M5670 9905 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M6950 9905 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M8230 9905 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M9510 9905 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M10790 9905 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M12070 9905 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M550 8625 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369 -369
  155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M1830 8625 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M3110 8625 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M4390 8625 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M5670 8625 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M6950 8625 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M8230 8625 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M9510 8625 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M10790 8625 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M12070 8625 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M550 7345 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369 -369
  155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M1830 7345 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M3110 7345 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M4390 7345 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M5670 7345 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M6950 7345 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M8230 7345 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M9510 7345 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M10790 7345 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M12070 7345 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M550 6065 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369 -369
  155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M1830 6065 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M3110 6065 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M4390 6065 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M5670 6065 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M6950 6065 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M8230 6065 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M9510 6065 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M10790 6065 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M12070 6065 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M550 4785 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369 -369
  155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M1830 4785 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M3110 4785 c-105 -35 -200 -141 -222 -248 -25 -117 32 -244 142 -319
  107 -74 229 -75 337 -3 177 118 201 338 53 485 -85 86 -207 119 -310 85z"/>
  <path d="M4390 4785 c-105 -35 -200 -141 -222 -248 -25 -117 32 -244 142 -319
  107 -74 229 -75 337 -3 177 118 201 338 53 485 -85 86 -207 119 -310 85z"/>
  <path d="M5670 4785 c-151 -50 -253 -216 -222 -362 25 -119 136 -230 255 -255
  193 -41 394 142 374 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M6950 4785 c-151 -50 -253 -216 -222 -362 25 -119 136 -230 255 -255
  193 -41 394 142 374 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M8230 4785 c-151 -50 -253 -216 -222 -362 25 -119 136 -230 255 -255
  193 -41 394 142 374 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M9510 4785 c-151 -50 -253 -216 -222 -362 25 -119 136 -230 255 -255
  193 -41 394 142 374 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M10790 4785 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M12070 4785 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M550 3505 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369 -369
  155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M1830 3505 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M3110 3505 c-105 -35 -200 -141 -222 -248 -25 -117 32 -244 142 -319
  107 -74 229 -75 337 -3 177 118 201 338 53 485 -85 86 -207 119 -310 85z"/>
  <path d="M4390 3505 c-105 -35 -200 -141 -222 -248 -25 -117 32 -244 142 -319
  107 -74 229 -75 337 -3 177 118 201 338 53 485 -85 86 -207 119 -310 85z"/>
  <path d="M5670 3505 c-151 -50 -253 -216 -222 -362 25 -119 136 -230 255 -255
  193 -41 394 142 374 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M6950 3505 c-151 -50 -253 -216 -222 -362 25 -119 136 -230 255 -255
  193 -41 394 142 374 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M8230 3505 c-151 -50 -253 -216 -222 -362 25 -119 136 -230 255 -255
  193 -41 394 142 374 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M9510 3505 c-151 -50 -253 -216 -222 -362 25 -119 136 -230 255 -255
  193 -41 394 142 374 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M10790 3505 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M12070 3505 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M550 2225 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369 -369
  155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M1830 2225 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M3110 2225 c-105 -35 -200 -141 -222 -248 -25 -117 32 -244 142 -319
  107 -74 229 -75 337 -3 177 118 201 338 53 485 -85 86 -207 119 -310 85z"/>
  <path d="M4390 2225 c-105 -35 -200 -141 -222 -248 -25 -117 32 -244 142 -319
  107 -74 229 -75 337 -3 177 118 201 338 53 485 -85 86 -207 119 -310 85z"/>
  <path d="M5670 2225 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M6950 2225 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M8230 2225 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M9510 2225 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M10790 2225 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M12070 2225 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M550 945 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369 -369
  155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M1830 945 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369 -369
  155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M3110 945 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369 -369
  155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M4390 945 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369 -369
  155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M5670 945 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369 -369
  155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M6950 945 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369 -369
  155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M8230 945 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369 -369
  155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M9510 945 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369 -369
  155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M10790 945 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  <path d="M12070 945 c-105 -35 -200 -141 -222 -248 -43 -206 163 -412 369
  -369 155 32 275 190 260 339 -11 105 -90 213 -190 262 -61 29 -155 36 -217 16z"/>
  </g>
  </svg>
  `;
}

export function plural(number: any, one: any, two: any, five: any) {
  let n = Math.abs(number);
  n %= 100;

  if (n.toString().includes('.')) {
    return two;
  }

  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
}


export function getEmptyArrayForTable<T>(pagination: IPagination<T>): any[] {
  if (pagination && pagination.per_page) {
    let n = pagination.per_page;
    const arr = [];
    while (n--) {
      arr.push({});
    }

    return arr;
  }

  return [];
}

export function getRows<T = any>(pagination: IPagination<T>): T[] {
  return pagination?.data || getEmptyArrayForTable<T>(pagination);
}

/** API DateTime format Day (YYYY-MM-DD) -> Day human-friendly format */
export function apiDayToHuDay(date: string) {
  return moment(date, moment.HTML5_FMT.DATE).format('DD.MM.YYYY');
}

/** SQL DateTime format -> Day human-friendly format */
export function sqlDateToHuDay(date: string) {
  return moment(date, moment.ISO_8601).format('DD.MM.YYYY');
}

export function timeToMs(date: string) {
  return new Date(date).getTime()
}

export function humanLikeTime(ms: number) {
  const delta = Math.round((+new Date - ms) / 1000);

  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  // const week = day * 7;

  if (delta < 30) {
    return 'только что';
  } else if (delta < minute) {
    const one = UITextCounted['секунда'].one;
    const two = UITextCounted['секунда'].two;
    const five = UITextCounted['секунда'].five;

    return delta + ` ${plural(delta, one, two, five)} назад`;
  } else if (delta < 2 * minute) {
    return 'минуту назад'
  } else if (delta < hour) {
    const minutes = Math.floor(delta / minute);

    const one = UITextCounted['минута'].one;
    const two = UITextCounted['минута'].two;
    const five = UITextCounted['минута'].five;

    return minutes + ` ${plural(minutes, one, two, five)} назад`;
  } else if (Math.floor(delta / hour) == 1) {
    return '1 час назад'
  } else if (delta < day) {
    const hours = Math.floor(delta / hour);

    const one = UITextCounted['час'].one;
    const two = UITextCounted['час'].two;
    const five = UITextCounted['час'].five;

    return hours + ` ${plural(hours, one, two, five)} назад`;
  } else if (delta < day * 2) {
    return 'вчера';
  } else {
    return new Date(ms).toLocaleString();
  }
}

export function humanLikeSize(size: number) {
  const sizeInMb = (size/1024/1024).toFixed(2);

  return `${sizeInMb} Мб`;
}

export function toSQLDateTimeFormat(x: string, timeZone: number = 0): string {
  if (x) {
    return new Date(x)
      .toISOString()
      .split('.')[0]
      .concat('+0' + timeZone + '00');
  }
  return '';
}

export function toInputDateTimeFormat(x: string): Date {
  return new Date(Date.parse(x));
}

export function getDateFromInputValue(value: any): Date {
  try {
    const a = new Date(Date.parse(value));
    return a && '' + a !== 'Invalid Date' ? a : value;
  } catch (err) {
    console.error(err);
    return value;
  }
}

export function parseRusDate(value: string): Date {
  let date = null;

  const rgx = new RegExp('^([0-9]{2}).([0-9]{2}).([0-9]{4})$'); // DD.MM.YYYY
  const match: Array<string> = rgx.exec(value);

  if (match != null) {
    date = new Date(Number(match[3]), Number(match[2]) - 1, Number(match[1]));
  }

  return date;
}

export function endOfDay(date: Date): Date {
  date.setDate(date.getDate() + 1);
  date.setSeconds(date.getSeconds() - 1);
  return date;
}

export function endOfQuarter(year: number, quarter: number): Date {
  const months = 3 * +quarter;
  let endMonth = '' + months;

  if (months < 10) {
    endMonth = '0' + endMonth;
  }

  const endMonthDays = moment(`${year}-${endMonth}-01`).daysInMonth();
  return moment(`${year}-${endMonth}-${endMonthDays}`).toDate();
}

export function createImageFromBlob(image: Blob): Observable<string | ArrayBuffer | null> {
  const reader = new FileReader();
  const imageToShowChange$ = new BehaviorSubject<string | ArrayBuffer | null>(null);
  reader.addEventListener(
    'load',
    () => {
      imageToShowChange$.next(reader.result);
      imageToShowChange$.complete();
    },
    false,
  );
  reader.addEventListener('error', (err) => imageToShowChange$.error(err));
  reader.addEventListener('abort', () => imageToShowChange$.complete());

  if (image) {
    reader.readAsDataURL(image);
  } else {
    imageToShowChange$.complete();
  }

  return imageToShowChange$.pipe(filter((x) => !!x));
}

export function deepClone<A>(obj: A): A {
  let a = null;
  if (obj) {
    try {
      a = JSON.parse(JSON.stringify(obj));
    } catch (err) {
      console.error(err);
    }
  }

  return a;
}

const fontAr = [1, 4, 5, 9, 10, 40, 50, 90, 100, 400, 500, 900, 1000, 4000, 5000, 9000, 10000];
const fontRom = ['I', 'IV', 'V', 'IX', 'X', 'XL', 'L', 'XC', 'C', 'CD', 'D', 'CM', 'M', 'M&#8577;', '&#8577;', '&#8577;&#8578;', '&#8578;'];

export function toRoman(num: number) {
  if (!num) {
    return '';
  }
  let result = '';
  let n = fontAr.length - 1;
  while (num > 0) {
    if (num >= fontAr[n]) {
      result += fontRom[n];
      num -= fontAr[n];
    } else {
      n--;
    }
  }
  return result;
}

/** Roman number to Arabic */
export function toArabic(str: string) {
  const MapArabicToRoman = { I: 1, V: 5, X: 10, L: 50, C: 100, D: 500, M: 1000 };
  return [...str].reduce(
    (r, c, i, s) =>
      MapArabicToRoman[<keyof typeof MapArabicToRoman>s[i + 1]] > MapArabicToRoman[<keyof typeof MapArabicToRoman>c]
        ? r - MapArabicToRoman[<keyof typeof MapArabicToRoman>c]
        : r + MapArabicToRoman[<keyof typeof MapArabicToRoman>c],
    0,
  );
}

export function getHash<T extends { [key: string]: any }>(array: T[], key: string = 'id', secondKey: string = null): { [key: string]: T } {
  const hash: { [key: string]: T } = {};

  if (array && Array.isArray(array)) {
    for (const item of array) {
      let index = '' + item[key];

      if (secondKey) {
        index += item[secondKey];
      }

      hash[index] = item;
    }
  }

  return hash;
}

export function undefinedOrNullOrEmptyString(...values: any[]) {
  for (const v of values) {
    const val = isString(v) ? v.trim() : v;
    if ([undefined, null, ''].indexOf(val) >= 0) {
      return true;
    }
  }

  return false;
}

export function checkEnteringString(searchString: string, searchVal: string): boolean {
  return ('' + searchString).toLocaleLowerCase().indexOf(('' + searchVal).toLocaleLowerCase()) >= 0;
}

export function highlightText(text: string, highlightString: string): string {
  if (undefinedOrNullOrEmptyString(text, highlightString)) {
    return text;
  }

  text = text.toString();
  highlightString = highlightString.toString();

  if (!text.length || !highlightString.length) {
    return text;
  }

  const index = text.toLocaleLowerCase().indexOf(highlightString.toLocaleLowerCase());

  if (index < 0) {
    return text;
  }

  const last = index + highlightString.length;
  const before = text.slice(0, index);
  const after = text.slice(last, text.length);
  const highlight = `<span class="rpn-autocomplete__highlight">${text.slice(index, last)}</span>`;

  return `${before}${highlight}${after}`;
}

export function hashMap<T extends { [K in keyof T]: any }, R>(object: T, mapper: (key: keyof T, value: T[keyof T]) => R) {
  const newObject = {} as { [K in keyof T]: R };

  for (const key of Object.keys(object)) {
    newObject[key] = mapper(key as keyof T, object[key]);
  }

  return newObject;
}

export function getRect(el: HTMLElement): IRect | null {
  try {
    return el && el.getBoundingClientRect().toJSON();
  } catch (err) {
    return null;
  }
}

export function intersectRect(a: IRect, b: IRect): boolean {
  return !(b.left >= a.right || b.right <= a.left || b.top >= a.bottom || b.bottom <= a.top);
}

export function findLastRouteWithUrl(route: ActivatedRoute): ActivatedRoute {
  let cursor: ActivatedRoute = route;

  while (cursor.firstChild) {
    cursor = cursor.firstChild;
  }

  while (cursor.snapshot.url.length === 0 && cursor.parent) {
    cursor = cursor.parent;
  }

  return cursor;
}

export function buildRouteUrlFromRoute(route: ActivatedRoute): string {
  let cursor: ActivatedRoute | null = route;
  const urls: UrlSegment[] = [];

  while (cursor) {
    if (cursor && cursor.snapshot && cursor.snapshot.url && cursor.snapshot.url.length) {
      urls.push(...cursor.snapshot.url);
    }

    cursor = cursor.firstChild;
  }

  return urls.map((u) => u.path).join('/');
}

export function innIsIp(inn: number|string): boolean {
  return inn ? inn.toString().length === 12 : false;
}

export function decimalRound(value: number | string, decimalPlaces = 12) {
  const roundConstant = Math.pow(10, decimalPlaces);
  return Math.round((+value + Number.EPSILON) * roundConstant) / roundConstant;
}

export function strNumFormat(value: string): string {
  if (undefinedOrNullOrEmptyString(value)) {
    return value;
  }

  value = decodeURI(value);
  value = value.replace(/\s/g, '');
  value = value.replace(/\,/, '.');

  return value;
}

export function decimalTrunc(value: string, decimalPlaces = 12): string {
  decimalPlaces = decimalPlaces < 0 ? 0 : decimalPlaces;
  return value.replace(/(\d+)(\.|,)(\d+)/g, function (o, a, b, c) {
    // Где o = всё совпадение целиком, a, b и с = совпадения из 1-й, 2-й и 3-й скобок.
    // символ \d находит совпадение с любой цифрой. Плюсик после него означает "один или несколько"
    return decimalPlaces ? a + b + c.slice(0, decimalPlaces) : a;
  });
}

export function nameToRGB(name: string) {
  const fakeDiv = document.createElement('div');
  fakeDiv.style.color = name;
  document.body.appendChild(fakeDiv);

  const cs = window.getComputedStyle(fakeDiv);
  const pv = cs.getPropertyValue('color');

  document.body.removeChild(fakeDiv);
  const rgb = pv.substr(4).split(')')[0].split(',');
  let r = (+rgb[0]).toString(16);
  let g = (+rgb[1]).toString(16);
  let b = (+rgb[2]).toString(16);

  if (r.length == 1) r = '0' + r;
  if (g.length == 1) g = '0' + g;
  if (b.length == 1) b = '0' + b;

  return '#' + r + g + b;
}

export function getContrastYIQ(hexcolor: string): string {
  if (hexcolor && hexcolor.indexOf('#') < 0) {
    hexcolor = nameToRGB(hexcolor);
  }
  hexcolor = hexcolor ? hexcolor.replace('#', '') : 'FFFFFF';
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 172 ? '#3b4256' : 'white';
}

export const mapColor = {
  draft: 'rgba(132,142,153,0.6)',
  manage_totals: 'rgba(98,176,255,0.6)',
  ready: 'rgba(113,188,144,0.6)',
  in_work: 'rgba(113,188,144,0.6)',
  edit: 'rgba(113,188,144,0.6)',
  to_edit: 'rgba(113,188,144,0.6)',
  to_registry: 'rgba(113,188,144,0.6)',
  paper_waiting: 'rgba(235,199,99,0.6)',
  to_review: 'rgba(235,199,99,0.6)',
  review: 'rgba(242,153,74,0.6)',
  to_canceled: 'rgba(209,77,108,0.6)',
  exclusion: 'rgba(242,153,74,0.6)',
  inclusion: 'rgba(242,153,74,0.6)',
  updating: 'rgba(242,153,74,0.6)',
  certificated: 'rgba(40,189,139,0.6)',
  done: 'rgba(40,189,139,0.6)',
  accepted: 'rgba(40,189,139,0.6)',
  approved: 'rgba(40,189,139,0.6)',
  returned: 'rgba(47,128,237,0.6)',
  import: 'rgba(161,97,171,0.6)',
  imported: 'rgba(161,97,171,0.6)',
  declined: 'rgba(229,57,53,0.6)',
  rejected: 'rgba(229,57,53,0.6)',
  deleted: 'rgba(229,57,53,0.6)',
  writeout: 'rgba(98,176,255,0.6)',
  act_signed: 'rgba(40,189,139,0.6)',
  act_to_make: 'rgba(98,176,255,0.6)',
  act_to_sign: 'rgba(98,176,255,0.6)',
  archive: 'rgba(132,142,153,0.6)',
  ca_check: 'rgba(98,176,255,0.6)',
  ca_edit_request: 'rgba(98,176,255,0.6)',
  canceled: 'rgba(229,57,53,0.6)',
  cancelled: 'rgba(229,57,53,0.6)',
  registration_rejected: 'rgba(69,69,69,0.6)',
  signature_waiting: 'rgb(128,128,128,0.6)',
  request_rejected: 'rgb(128,0,0,0.6)',
  waiting_request_approval: 'rgba(113,188,144,0.6)',
  waiting_request_rejection: 'rgb(255,204,203,0.6)',
  request_approved: 'rgb(0,128,0,0.6)',
  waiting_treasury_request: 'rgb(128,128,128,0.6)',
  sent_to_treasury: 'rgb(128,128,128,0.6)',
  refund_fulfilled: 'rgb(0,128,0,0.6)',
};

export function getRandomColor(): string {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function coordinatesIsValid(coordinates: [number, number] | number[]) {
  return (
    coordinates
      .filter((c) => !undefinedOrNullOrEmptyString(c))
      .map((c) => Number(c))
      .filter((c) => c !== 0 && !isNaN(c)).length === 2
  );
}

export function getValidCoord(val: number, full: number) {
  let sign = val > 0 ? 1 : -1;

  const turnovers = Math.abs(val) / full;
  const spin = turnovers - Math.floor(turnovers);

  let calc: number;

  if (spin - 0.5 > 0) {
    calc = full - spin * full;
    sign = sign * -1;
  } else {
    calc = spin * full;
  }

  return sign * calc;
}

export function geoCoordinateToGost(coordinate: number, decimalPlaces = 6) {
  return Number(Number(coordinate).toFixed(decimalPlaces));
}

export function getMonitoringPollutant(pollutants: any) {
  const keys = Object.keys(pollutants);

  let first;

  for (const key of keys) {
    if (!first) {
      first = pollutants[key];
    }

    if (pollutants[key] && pollutants[key].is_active) {
      return pollutants[key];
    }
  }

  return first;
}

export function fetchPagination<T = any>(
  fn: (event: IPaginationChangeEvent, prevData: IPagination<T> | null) => Observable<IPagination<T>>,
  event: IPaginationChangeEvent,
): Observable<T> {
  const page$ = new BehaviorSubject(event);

  let prevData: IPagination<T> | null = null;

  const data = [];

  return page$.pipe(
    switchMap((paginationEvent) => {
      return fn(paginationEvent, prevData);
    }),
    filter((x) => !!x),
    map((resp) => {
      prevData = resp;

      const items = get(resp, 'data') || [];
      data.push(...items);

      const isLastPage = !(event.current_page < resp.last_page);

      if (!isLastPage) {
        setTimeout(() => {
          event.current_page += 1;
          page$.next(event);
        }, 1000 * 5);
      } else {
        fn(event, prevData);
      }

      return { data } as any;
    }),
  );
}

export function getYearsForDictionary(minYear = 1970, maxYear: number | null = null, reverse = false, plusYears = 0): IDictionaryItem[] {
  if (!maxYear) {
    maxYear = +new Date().getFullYear() + plusYears;
  }

  const years: IDictionaryItem[] = [];

  for (let i = minYear; i <= maxYear; i++) {
    years.push({
      id: i,
      name: '' + i,
    });
  }

  return reverse ? years.reverse() : years;
}

export function sSplice(string, start, delCount, newSubStr) {
  return `${string.slice(0, start)}${newSubStr}${string.slice(start + Math.abs(delCount))}`;
}

export class FiltersUtils {
  params: any;

  constructor(params: any) {
    this.params = {
      ...params,
      filters: { ...params.filters },
    };
  }

  get(filterName: string): string | number | boolean {
    return this.params.filters && this.params.filters[filterName];
  }

  set(filterName: string, value: string | number | boolean): void {
    if (!this.params.filters) {
      this.params.filters = {};
    }

    this.params.filters[filterName] = value;
  }

  clear(filterName: string): void {
    if (!this.params.filters) {
      return;
    }

    delete this.params.filters[filterName];
  }

  checkAndSetDateFilter(filterName: string, filterType = 'filter_date_between') {
    this.checkAndSetBetweenFilter(filterName, filterType, (v, d) => v || d || '', '', '');
  }

  private checkAndSetBetweenFilter(
    filterFrom: string,
    filterType: string,
    get: (v: string, d: string) => string,
    defaultFrom: string,
    defaultTo: string,
  ) {
    const filterTo = `${filterFrom}_to`;

    if (this.params.filters[filterFrom] || this.params.filters[filterTo]) {
      const amount = get(this.params.filters[filterFrom], defaultFrom);
      const amount_to = get(this.params.filters[filterTo], defaultTo);

      delete this.params.filters[filterFrom];
      delete this.params.filters[filterTo];

      this.params.filters[`${filterType}[${filterFrom}]`] = amount_to ? `${amount}...${amount_to}` : amount;
    }
  }

  setUserId(user, checkboxName = 'is_my', userIdName = 'user_id') {
    if (!this.params.filters) {
      return;
    }

    if (this.params.filters[checkboxName]) {
      this.params.filters[userIdName] = user.id;
    } else {
      delete this.params.filters[userIdName];
    }

    delete this.params.filters[checkboxName];
  }
}

export function signDiff(
  data: any,
  prop: string,
  signData: any,
  signProp: string,
  section: string,
  name: string,
  matchFn: (a, b) => boolean = (a, b) => a === b,
) {
  const a = get(data, prop);
  const b = get(signData, signProp);

  const isEqual = matchFn(get(data, prop), get(signData, signProp));

  if (isEqual) {
    return null;
  }

  return {
    title: `Данные из подписи не совпадают с данными из раздела "${section}"`,
    a,
    b,
    aTitle: `${name} в разделе`,
    bTitle: `${name} в подписи`,
  };
}

export function fixedGroupId(id: number): string {
  return id === 143 || id === 144 ? '49' : ('' + id).length > 2 ? '-' : '' + (id || '');
}

export function validatePhone(control: UntypedFormControl) {
  const phone = /^\+7\(\d{3}\)\d{3}\-\d{2}\-\d{2}$/;
  return { message: !control.value || phone.test(control.value) ? null : 'Неверный телефон' };
}

export function isURLContains(url: string, path: string): boolean {
  return url.includes(path);
}

export function getSearchOption(sendKey, searchKey, searchString: string): any {
  return { [sendKey]: searchString, [searchKey]: searchString };
}

export function templateFilter(arr: IDictionaryItem[], templateFilterFunction: (item: any) => boolean = () => true): IDictionaryItem[] {
  arr = arr.filter(templateFilterFunction);
  return arr;
}

export function rebuildSuggestOption(
  value: any,
  optionsKeys: string[],
  searchKey: string,
  withOutBracketsOptionsKeys: string[],
  sendKey: string,
): string {
  if (!value) return '';

  let extraValue = '';
  if (optionsKeys?.length) {
    optionsKeys.forEach((key) => {
      const data = get(value, key, '');
      extraValue +=
        searchKey === key || withOutBracketsOptionsKeys.indexOf(key) >= 0 ? (data ? ' ' + data + ' ' : '') : data ? ' [' + data + '] ' : '';
    });
  } else {
    extraValue = get(value, searchKey) || get(value, sendKey);
  }

  return extraValue || '' + value;
}

export function trackBy(index: number, item: IDictionaryItem): string | number | boolean | undefined {
  return item?.id;
}

export interface ComponentWithSubscriptions extends OnDestroy {
  subscriptions: Subscription[];
}

export function plus(component: ComponentWithSubscriptions, ...observables: Observable<any>[]) {
  for (const ob of observables) {
    const sub = ob.subscribe();

    component.subscriptions.push(sub);
  }
}

export function copyToClipboard(text: string) {
  if ((window as any).clipboardData && (window as any).clipboardData.setData) {
    return (window as any).clipboardData.setData('text', text);
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea');

    textarea.textContent = text;

    textarea.style.position = 'absolute';
    textarea.style.top = '0';
    textarea.style.left = '-9999px';

    textarea.style.width = '2em';
    textarea.style.height = '2em';

    textarea.style.padding = '0';

    textarea.style.border = 'none';
    textarea.style.outline = 'none';
    textarea.style.boxShadow = 'none';

    textarea.style.background = 'transparent';

    document.body.appendChild(textarea);

    textarea.focus();
    textarea.select();

    try {
      return document.execCommand('copy');
    } catch (ex) {
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
}

export function controlValuePipe(control: AbstractControl) {
  return control.valueChanges.pipe(startWith(control.value));
}

export function getC(b, t: string) {
  return H.stringify(getSH(b, t));
}

export function addIdToUrlWithQueryParams(url: string, id: string | number): string {
  if (!url) return url;

  const urlParamArray = url.split('?');
  if (urlParamArray?.length === 2) return `${urlParamArray[0]}/${id}?${urlParamArray[1]}`;

  return `${url}/${id}`;
}

export function filteringDictByFilters(dict: IDictionaryItem[], filters: { [key: string]: any }, dictKey): any {
  const keys = Object.keys(filters);
  let res = deepClone(dict || []);

  if (!(keys?.length && res?.length)) return res;

  keys.forEach((key) => {
    let filterApplied = false;
    let itemKey = key;
    let filter_except = false;

    if (key.includes('[')) {
      const keyArray = key.split('[');

      if (keyArray?.[0] === 'filter_except') filter_except = true;
      if (keyArray?.length === 2) itemKey = keyArray[1].split(']')[0];
    }

    res = res.filter((item) => {
      filterApplied = true;

      if (!Object.prototype.hasOwnProperty.call(item, itemKey)) return true;
      if (item[itemKey] === null) {
        if (item[itemKey] === filters[key]) return true;
        return false;
      }

      const isItemArray = isArray(item[itemKey]);
      if (item[itemKey].length === 0) return false;

      const isFilterArray = isArray(filters[key]);
      const isFilterStringArray = !isFilterArray && filters[key] && typeof filters[key] === 'string'
        && filters[key].toString().split(',').length > 1;

      let intersection = [];
      if (isItemArray && (isFilterArray || isFilterStringArray)) {
        const filtersArr = isFilterStringArray ? filters[key].split(',') : filters[key];
        intersection = filtersArr.filter(x => item[itemKey].includes(x));
      } else if (isFilterArray || isFilterStringArray) {
        const filtersArr = isFilterStringArray ? filters[key].split(',') : filters[key];
        intersection = filtersArr.filter(x => ('' + x) === ('' + item[itemKey]));
      } else if (isItemArray) {
        intersection = item[itemKey].filter(x => x === filters[key]);
      } else {
        intersection = item[itemKey] === filters[key] ? [item[itemKey]] : [];
      }

      const res = intersection.length > 0;
      return filter_except ? !res : res;
    });

    if (!filterApplied && res.length) console.log(`Property "${itemKey}" in Dict "${dictKey}" for filtering were not found; filter was not applied;`);
  });
  return res;
}

export function checkUrl(url: string, urlTemplate: string): boolean {
  if (!url) {
    return false;
  }

  const urlArr = url.split('/');
  const urlTemplateArr = urlTemplate.split('/');

  let cursor = 0;

  while (typeof urlTemplateArr[cursor] === 'string') {
    if (urlTemplateArr[cursor] === '$') {
      urlArr[cursor] = '$';
    }

    cursor++;
  }

  return urlArr.join('/') === urlTemplate;
}

export function getIdentId(b, a) {
  const t = Date.now() + 'a';
  return t + getC(b + a, t);
}

export function base64ToBytes(base64) {
  const binString = atob(base64);
  return Uint8Array.from(binString, (m) => m.codePointAt(0));
}

const splitWordRegexp = /\s+/g;
export function splitWords(textStr: string) {
  return textStr.split(splitWordRegexp);
}

export function serializerINavTabFromBackend(item: INavTabFromBackend, jwtToken: string, level = 0): INavTab {
  const isDisabled = item.url === 'empty';
  const isHref = item.url?.includes('https://');
  const showChildren = level < 1;
  const routerLink = !isHref ? item.url : null;
  const href = isHref
    ? item.url
      .replace('${environment.DOMAIN_V1}', environment.DOMAIN_V1)
      .replace('${environment.DOMAIN_CONSTRUCTOR}', `${environment.DOMAIN_CONSTRUCTOR}?jwt=${jwtToken}`)
    : null;
  const children = item.child?.length ? item.child.map(ch => serializerINavTabFromBackend(ch, jwtToken, level + 1)) : null

  return {
    title: item.name,
    fullTitle: item.name,
    code: item.code,
    routerLink,
    href,
    isDisabled,
    isDisplayed: true,
    showChildren,
    children,
  } as INavTab;
}